import React, { useState } from 'react';

const Section = ({sectionClass, contentClass, title, toggle=true, children}) => {
    const [isOpen, setOpen] = useState(false);
    const open = isOpen ? "is-open" : "";
    return (
        <section className={"Info "+ sectionClass}>
            <h2 
            className={toggle ? "js-toggleSection h4 " + open : "h4"}
            onClick={() => setOpen(!isOpen)}
            >{title}</h2>
            <div className={toggle ? "js-section " + contentClass + " " + open : contentClass}>{children}</div>
        </section>
    )
}

export default Section;