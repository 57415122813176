import React from 'react';
import data from './data.json';
import logotype from "./img/logotype.png"

function Header() {
    return(
    <header className="Header" itemScope="" itemType="http://schema.org/Person">
        <a className="Logo" href="/"><img src={logotype} alt={data.contact.data.name+" : "+data.contact.data.title} /></a>
        <span itemProp="name">{data.contact.data.name}</span>
        <span itemProp="jobTitle">{data.contact.data.title}</span>
        <div itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">{data.contact.data.street}</span><br />
            <span itemProp="addressLocality">{data.contact.data.city}</span>,
            <span itemProp="addressRegion">{data.contact.data.state}</span>
            <span itemProp="postalCode">{data.contact.data.zip}</span>
        </div>
        <a itemProp="email" href={"mailto:"+data.contact.data.email} title={"Email "+data.contact.data.name}>{data.contact.data.email}</a><br />
        <a itemProp="url" href={"https://"+data.contact.data.url} title={data.contact.data.name}>{data.contact.data.url}</a><br />
        <a itemProp="telephone" href={"tel:"+data.contact.data.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-','')} target="_blank" rel="noopener noreferrer">{data.contact.data.phone}</a>
        
    </header>
    )
}

export default Header;