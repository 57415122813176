import React from 'react';
import Section from './Section';
import data from './data.json';

function Work() {
    return(
        <Section 
        sectionClass="WorkSection"
        contentClass="work"
        title={data.work.name}
        >
            {data.work.data.map(job => (
                <div className="Job" key={job.name}>
                    <h3 className="Job__employer  h5">{job.name}</h3>
                    <time className="Job__date">{job.dates}</time>
                    <div className="Job__title">{job.position}</div>
                    <p className="Job__description" >{job.desc}</p>
                </div>
            ))}
        </Section>
    )
}

export default Work;