import React from 'react';
import Section from './Section';
import data from './data.json';

function Education() {
    return (
        <Section 
          sectionClass="EduSection" 
          contentClass="edu"
          title={data.edu.name}
          > 
            {data.edu.data.map(school => (
                <div className="School" key={school.name}>
                    <h3 className="h5">{school.name}</h3>
                    <div>{school.deets}</div>
                    <div className="School__date">{school.date}</div>
                    <div>{school.grade}</div>
                </div>
            ))}
        </Section>
    )
}

export default Education;