import React from 'react';
import Section from './Section';
import data from './data.json';

function Samples() {
    return(
        <Section 
        sectionClass="Info SamplesSection"
        contentClass="samples"
        title={data.samples.name}
        >
            {data.samples.data.map(sample => (
                <div className="Sample" key={sample.name}>
                    <a className="h5  bold" href={sample.link} title={sample.name}>{sample.name}</a>
                    <div className="Sample__desc">{sample.desc}</div>
                </div>
            ))}
        </Section>
    )
}

export default Samples;