import React from 'react';
import Section from './Section';
import data from './data.json';

function Skills() {
    var rows = data.skills.data.map(skill => {
        //this works but isn't very DRY, 
        //feels like there should be a better way
        if(skill.link2 === "") {
            return(
                <li key={skill.name}>
                    <a href={skill.link} title={skill.name}>{skill.name}</a>
                </li>
            );
        } else {
            return(
                <li key={skill.name + skill.name2}>
                    <a href={skill.link} title={skill.name}>{skill.name}</a> / <a href={skill.link2} title={skill.name2}>{skill.name2}</a>
                </li>
            )
        }
    });
    return(
        <Section 
        sectionClass="SkillsSection"
        contentClass="skills"
        title={data.skills.name}
        >
            <ul>
                {rows}
            </ul>
        </Section>
    )
}

export default Skills;