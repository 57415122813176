import React from 'react';
import Section from './Section';
import data from './data.json';

function Extras() {
    return(
        <Section 
        sectionClass="ExtrasSection"
        contentClass=""
        title={data.extras.name} 
        toggle={false}
        >
            {data.extras.data.map(extra => (
                <div key={extra.name}>
                    <a href={extra.link} title={extra.name}>{extra.name}</a>
                </div>
            ))}
        </Section>
    )
}

export default Extras;