import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from './Header';
import Education from './Education';
import Skills from './Skills'
import Samples from './Samples';
import Work from './Work';
import Extras from './Extras';

ReactDOM.render(
  <div>
  <Header />
  <main>
    <Education />
    <Skills />
    <Work />
    <Samples />
    <Extras />
  </main>
  </div>,
  document.getElementById('root'));